import React, { Component } from 'react'
import './ad.css'

export class Advertisement extends Component {
  render() {
    return (
      <div>
         {/*banner here */}
         {/* <section className="banner-area relative" id="home">
          <div className="overlay overlay-bg" />
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="about-content col-lg-12">
                <h1 className="text-white">Advertisement</h1>
                <p className="text-white link-nav">
                  <a href="index.html">Home </a>{" "}
                  <span className="lnr lnr-arrow-right" />{" "}
                  <a href="single.html"> Advertisement </a>
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/*end banner */}
        {/*content begin */}
            <section className="custom-content">
            <div>
            {/*content */}
            <section className="post-area section-gap">
              <div className="container">
                <div className="row justify-content-center d-flex">
                  <div className="col-lg-8 post-list">
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4> महाराष्ट्र लोकसेवा आयोगामार्फत अभियांत्रिकी पदाच्या ११६१ जागा</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        महाराष्ट्र शासनाच्या विविध विभागांतर्गत अभियांत्रिकी संवर्गातील विविध पदाच्या ११६१ जागा भरण्यासाठी महाराष्ट्र लोकसेवा आयोगामार्फ़त महाराष्ट्र अभियांत्रिकी सेवा पूर्व परीक्षा २०१९ परीक्षा रविवार, दिनांक…
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>सचिन ढवळे अकॅडमीत स्पेशल अंकगणित व बुद्धिमापन बॅच उपलब्ध</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        पुणे येथे सचिन ढवळे यांच्या गणित आणि तर्कशास्त्र अकॅडमीत वनसेवा व सरळसेवा/ UPSC/ MPSC-CSAT/ Railway/ SSC/Banking परीक्षेसाठी अंकगणित व बुद्धिमापन विषयाची संपूर्ण तयारी करण्यासाठी २ एप्रिल २०१९ पासून…
                        </p>
                        {/* <h5>Job Nature: Full time</h5> */}
                        {/* <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>भारतीय स्टेट बँकेत प्रोबेशनरी ऑफिसर पदाच्या एकूण २००० जागा</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        भारतीय स्टेट बँक यांच्या आस्थापनेवरील प्रोबेशनरी ऑफिसर पदाच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत. प्रोबेशनरी ऑफिसर (PO) पदाच्या २००० जागा…
                    
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>पुणे येथे सरळसेवा/ तलाठी मेगाभरती परीक्षा स्पेशल बॅच उपलब्ध</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        एकलव्य अकॅडमी, पुणे येथे आगामी सरळसेवा/ तलाठी सरळसेवा मेगाभरती परीक्षेची तयारी करण्यासाठी सुरु होत असलेल्या ५० दिवसांच्या स्पेशल बॅच (सकाळ/ सायंकाळ) करिता प्रवेश देणे सुरु आहे. प्रवेश मर्यादित…
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>आयडीबीआय बँकेत सहाय्यक व्यवस्थापक पदाच्या एकूण ५०० जागा</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        आयडीबीआय बँक यांच्या आस्थापनेवरील सहाय्यक व्यवस्थापक पदाच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत. सहाय्यक व्यवस्थापक पदाच्या ५०० जागा…
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>जिल्हा परिषदांच्या आस्थापनेवर विविध पदांच्या एकूण १३५७० जागा</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        राज्यातील विविध जिल्हा परिषदांच्या आस्थापनेवरील विविध पदांच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून केवळ ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत. जिल्हा परिषदांतील विविध पदांच्या…
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                    <div className="single-post d-flex flex-row">
                      <div className="thumb">
                        <img src={require("../../img/post.png")} alt="" />
                        <ul className="tags">
                          <li>
                            <a href="#">Art</a>
                          </li>
                          <li>
                            <a href="#">Media</a>
                          </li>
                          <li>
                            <a href="#">Design</a>
                          </li>
                        </ul>
                      </div>
                      <div className="details">
                        <div className="title d-flex flex-row justify-content-between">
                          <div className="titles">
                            <a href="single.html">
                              <h4>वणी (यवतमाळ) येथे बांधकाम पर्यवेक्षक कोर्स करिता प्रवेश देणे सुरु आहे</h4>
                            </a>
                            {/* <h6>Premium Labels Limited</h6> */}
                          </div>
                          {/* <ul className="btns">
                            <li>
                              <a href="#">
                                <span className="lnr lnr-heart" />
                              </a>
                            </li>
                            <li>
                              <a href="#">Apply</a>
                            </li>
                          </ul> */}
                        </div>
                        <p>
                        वणी, मारेगाव, वरोरा, चंद्रपूर, घुगुस, भद्रावती येथील विद्यार्थ्यांकरिता श्री विश्वकर्मा व्होकेशनल ट्रेनिंग सेंटर, वणी या संस्थेत स्थापत्य अभियांत्रिकी सहाय्यक पदाकरिता उपयुक्त सरकार मान्य असलेल्या…
                        </p>
                        {/* <h5>Job Nature: Full time</h5>
                        <p className="address">
                          <span className="lnr lnr-map" /> 56/8, Panthapath
                          Dhanmondi Dhaka
                        </p>
                        <p className="address">
                          <span className="lnr lnr-database" /> 15k - 25k
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 sidebar">
                
    
                    <div className="single-slidebar">
                      <h4>Top rated job posts</h4>
                      <div className="active-relatedjob-carusel">
                        <div className="single-rated">
                          <img
                            className="img-fluid"
                            src={require("../../img/r1.jpg")}
                            alt=""
                          />
                          <a href="single.html">
                            <h4>Creative Art Designer</h4>
                          </a>
                          <h6>Premium Labels Limited</h6>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod temporinc ididunt ut dolore magna
                            aliqua.
                          </p>
                          <h5>Job Nature: Full time</h5>
                          <p className="address">
                            <span className="lnr lnr-map" /> 56/8, Panthapath
                            Dhanmondi Dhaka
                          </p>
                          <p className="address">
                            <span className="lnr lnr-database" /> 15k - 25k
                          </p>
                          <a href="#" className="btns text-uppercase">
                            Apply job
                          </a>
                        </div>
                      </div>
                    </div>
    
                    <div className="single-slidebar">
                      <h4>Jobs by Category</h4>
                      <ul className="cat-list">
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Technology</p>
                            <span>37</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Media & News</p>
                            <span>57</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Goverment</p>
                            <span>33</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Medical</p>
                            <span>36</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Restaurants</p>
                            <span>47</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Developer</p>
                            <span>27</span>
                          </a>
                        </li>
                        <li>
                          <a
                            className="justify-content-between d-flex"
                            href="category.html"
                          >
                            <p>Accounting</p>
                            <span>17</span>
                          </a>
                        </li>
                      </ul>
                    </div>
    
                    <div className="single-slidebar">
                      <h4>Carrer Advice Blog</h4>
                      <div className="blog-list">
                        <div className="single-blog bg-blog1">
                          <a href="single.html">
                            <h4>
                              Home Audio Recording <br />
                              For Everyone
                            </h4>
                          </a>
                          <div className="meta justify-content-between d-flex">
                            <p>02 Hours ago</p>
                            <p>
                              <span className="lnr lnr-heart" />
                              06
                              <span className="lnr lnr-bubble" />
                              02
                            </p>
                          </div>
                        </div>
                        <div className="single-blog bg-blog2">
                          <a href="single.html">
                            <h4>
                              Home Audio Recording <br />
                              For Everyone
                            </h4>
                          </a>
                          <div className="meta justify-content-between d-flex">
                            <p>02 Hours ago</p>
                            <p>
                              <span className="lnr lnr-heart" />
                              06
                              <span className="lnr lnr-bubble" />
                              02
                            </p>
                          </div>
                        </div>
                        <div className="single-blog bg-blog1">
                          <a href="single.html">
                            <h4>
                              Home Audio Recording <br />
                              For Everyone
                            </h4>
                          </a>
                          <div className="meta justify-content-between d-flex">
                            <p>02 Hours ago</p>
                            <p>
                              <span className="lnr lnr-heart" />
                              06
                              <span className="lnr lnr-bubble" />
                              02
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*eend conten */}
            <section className="callto-action-area section-gap">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="menu-content col-lg-9">
                                <div className="title text-center">
                                    <h1 className="mb-10 text-white">Join us today without any hesitation</h1>
                                    <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                    <a className="primary-btn" href="#">I am a Candidate</a>
                                    <a className="primary-btn" href="#">Request Free Demo</a>
                                </div>
                            </div>
                        </div>	
                    </div>	
                </section>
          </div>
            </section>
        {/*end conten begin */}
      </div>
    )
  }
}

export default Advertisement
