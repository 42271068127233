import React, { Component } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
export class Navbar extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
        <header id="header">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark sticky-top">

  <div class="navbar-toggler-right">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
  </div>




  <div class="collapse navbar-collapse flex-column custom-navbar-color-code" id="navbar">
  <ul className="navbar-nav custom-bg-ul w-100">
  <nav class="ap-navbar">
  {/* <a class="navbar-brand" style={{float : 'left'}}>Navbar Search Box Auto Expand </a> */}
  <a class="navbar-brand" style={{float : 'right'}}>
  <ul className="list-inline">
      <li className="list-inline-item"><a href={'https://www.facebook.com/'}><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://twitter.com/'}><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://in.pinterest.com/'}><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
      <li className="list-inline-item"><a href={'https://www.youtube.com/'}><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
  </ul>
   </a>
    
</nav>
  </ul>
    {/* <ul class="navbar-nav justify-content-left w-100 bg-secondary px-3">
      <li class="nav-item active" style={{float : 'right'}}>
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown link
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown link
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown link
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
        
      </li>
    </ul> */}

  {/*second navbar */}

      <ul class="navbar-nav  w-100 px-3 custom-ul-color"> 
      <li class="nav-item">
        {/* <a class="nav-link" href="#">Home */}
        <a className="navbar-brand" href={"/"}>
                  <img src={require("../../img/logo.png")} alt="" title="" /> 
         <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
      <a className="nav-link">
                      <NavLink to="/" style={{ fontFamily: "Mangal" }}>
                        {" "}
                        मनोगत{" "}
                      </NavLink>
                      </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                      <NavLink to="/Advertisement" activeClassName="selected">जाहिराती</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/Category" activeClassName="selected"> Category</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/Mega" activeClassName="selected">मेगा भरती</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/Result" activeClassName="selected">निकाल</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/lakshvedh" activeClassName="selected">लक्षवेध</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/magova" activeClassName="selected">मागोवा</NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link">
                    <NavLink to="/Online" activeClassName="selected"> प्रणाली </NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#">
                    <NavLink to="/HallTicket" activeClassName="selected">   परवेशपत्र </NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href='#'>
                    <NavLink to="/Syllabus" activeClassName="selected">   अभ्यासक्रम </NavLink>
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href='#'>
                    ग्रंथसूची
                    </a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href='#'>
                    मदत केंद्र
                    </a>
                    </li> 
                    
                    <li className="nav-link">
                      <a className="nav-item" href={"./02-2019_Indian_Railway_Group_D.pdf"} download>
                        Download
                      </a>
                    </li>
                    {/* <li class="menu-has-children">
                      <a href="">Pages</a>
                      <ul>
                        <li>
                          <a href="elements.html">elements</a>
                        </li>
                        <li>
                          <a href="search.html">search</a>
                        </li>
                        <li>
                          <a href="single.html">single</a>
                        </li>
                      </ul>
                    </li> */}
                    <li className="nav-link">
                      <a class="ticker-btn" href={"/Login"}>
                        Signup
                      </a>
                    </li>
                    <li className="nav-link">
                      <a class="ticker-btn" href={"/Login"}>
                        Login
                      </a>
                    </li>
    </ul>

  </div>

</nav>
</header>
        </div>
      </React.Fragment>
    );
  }
}

export default Navbar;
