import React, { Component } from 'react'
import './online.css'
export class Online extends Component {
  render() {
    return (
        <div>
        {/*banner here */}
        {/* <section className="banner-area relative" id="home">
          <div className="overlay overlay-bg" />
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="about-content col-lg-12">
                <h1 className="text-white">Syllabus</h1>
                <p className="text-white link-nav">
                  <a href="index.html">Home </a>{" "}
                  <span className="lnr lnr-arrow-right" />{" "}
                  <a href="single.html"> Syllabus </a>
                </p>
              </div>
            </div>
          </div>
        </section> */}
        {/*end banner */}
        {/*content */}
        <section className="post-area section-gap">
          <div className="container">
            <div className="row justify-content-center d-flex">
              <div className="col-lg-8 post-list">
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>आयडीबीआय बँकेत विशेष व्यवस्थापक पदांच्या एकूण १२० जागा</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                    आयबीपीएस आठव्या लिपिक मुख्य परीक्षा निकाल उपलब्ध
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4> जिल्हा परिषदांच्या आस्थापनेवर विविध पदांच्या १३५७० जागा (मुदतवाढ)
</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>महाराष्ट्र लोकसेवा आयोगामार्फत गट-क संवर्गातील पदांच्या २३४ जागा
</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>भारतीय स्टेट बँकेच्या आस्थापनेवर कनिष्ठ सहयोगी पदाच्या ८९०४ जागा</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>केंद्रीय लोकसेवा आयोगामार्फत केंदीय आरोग्य सेवा विभागात ९६५ जागा</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>भारतीय सीमा सुरक्षा दलात कॉन्स्टेबल पदाच्या एकूण १०७२ जागा</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
                <div className="single-post d-flex flex-row">
                  {/* <div className="thumb">
                    <img src={require("../../img/post.png")} alt="" />
                    <ul className="tags">
                      <li>
                        <a href="#">Art</a>
                      </li>
                      <li>
                        <a href="#">Media</a>
                      </li>
                      <li>
                        <a href="#">Design</a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="details">
                    <div className="title d-flex flex-row justify-content-between">
                      <div className="titles">
                        <a href="single.html">
                          <h4>एअर इंडिया लिमिटेड यांच्या आस्थापनेवर विविध पदाच्या ७९ जागा</h4>
                        </a>
                        {/* <h6>Premium Labels Limited</h6> */}
                      </div>
                      {/* <ul className="btns">
                        <li>
                          <a href="#">
                            <span className="lnr lnr-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#">Apply</a>
                        </li>
                      </ul> */}
                    </div>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod temporinc ididunt ut dolore magna aliqua.
                    </p>
                    <h5>Job Nature: Full time</h5>
                    <p className="address">
                      <span className="lnr lnr-map" /> 56/8, Panthapath
                      Dhanmondi Dhaka
                    </p>
                    <p className="address">
                      <span className="lnr lnr-database" /> 15k - 25k
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 sidebar">
            

                <div className="single-slidebar">
                  <h4>Top rated job posts</h4>
                  <div className="active-relatedjob-carusel">
                    <div className="single-rated">
                      <img
                        className="img-fluid"
                        src={require("../../img/r1.jpg")}
                        alt=""
                      />
                      <a href="single.html">
                        <h4>Creative Art Designer</h4>
                      </a>
                      <h6>Premium Labels Limited</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod temporinc ididunt ut dolore magna
                        aliqua.
                      </p>
                      <h5>Job Nature: Full time</h5>
                      <p className="address">
                        <span className="lnr lnr-map" /> 56/8, Panthapath
                        Dhanmondi Dhaka
                      </p>
                      <p className="address">
                        <span className="lnr lnr-database" /> 15k - 25k
                      </p>
                      <a href="#" className="btns text-uppercase">
                        Apply job
                      </a>
                    </div>
                  </div>
                </div>

                <div className="single-slidebar">
                  <h4>Jobs by Category</h4>
                  <ul className="cat-list">
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Technology</p>
                        <span>37</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Media & News</p>
                        <span>57</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Goverment</p>
                        <span>33</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Medical</p>
                        <span>36</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Restaurants</p>
                        <span>47</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Developer</p>
                        <span>27</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="justify-content-between d-flex"
                        href="category.html"
                      >
                        <p>Accounting</p>
                        <span>17</span>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* <div className="single-slidebar">
                  <h4>Carrer Advice Blog</h4>
                  <div className="blog-list">
                    <div className="single-blog bg-blog1">
                      <a href="single.html">
                        <h4>
                          Home Audio Recording <br />
                          For Everyone
                        </h4>
                      </a>
                      <div className="meta justify-content-between d-flex">
                        <p>02 Hours ago</p>
                        <p>
                          <span className="lnr lnr-heart" />
                          06
                          <span className="lnr lnr-bubble" />
                          02
                        </p>
                      </div>
                    </div>
                    <div className="single-blog bg-blog2">
                      <a href="single.html">
                        <h4>
                          Home Audio Recording <br />
                          For Everyone
                        </h4>
                      </a>
                      <div className="meta justify-content-between d-flex">
                        <p>02 Hours ago</p>
                        <p>
                          <span className="lnr lnr-heart" />
                          06
                          <span className="lnr lnr-bubble" />
                          02
                        </p>
                      </div>
                    </div>
                    <div className="single-blog bg-blog1">
                      <a href="single.html">
                        <h4>
                          Home Audio Recording <br />
                          For Everyone
                        </h4>
                      </a>
                      <div className="meta justify-content-between d-flex">
                        <p>02 Hours ago</p>
                        <p>
                          <span className="lnr lnr-heart" />
                          06
                          <span className="lnr lnr-bubble" />
                          02
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/*eend conten */}
        <section className="callto-action-area section-gap">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="menu-content col-lg-9">
                            <div className="title text-center">
                                <h1 className="mb-10 text-white">Join us today without any hesitation</h1>
                                <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                <a className="primary-btn" href="#">I am a Candidate</a>
                                <a className="primary-btn" href="#">Request Free Demo</a>
                            </div>
                        </div>
                    </div>	
                </div>	
            </section>
      </div>
    )
  }
}

export default Online
