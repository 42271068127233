import React, { Component } from 'react'
import './Login.css'
import {Link, NavLink}from 'react-router-dom'
export class Login extends Component {
  render() {
    return (
      <div>
         <section className="banner-area relative" id="home">
          <div className="overlay overlay-bg" />
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="about-content col-lg-12">
              <div class="container">
        	<div class="row justify-content-center">
            	<div class="col-xl-4 col-lg-5 col-md-6 col-sm-8">
                	<div id="login">
                    		<div class="text-center"><img src={require("../../img/logo.png")} alt="Image" data-retina="true" /></div>
                            <hr />
                            <form>
                            <a href="#0" class="social_bt facebook">Login with Facebook</a>
							<a href="#0" class="social_bt google">Login with Google</a>
							<div class="divider"><span>Or</span></div>
                                <div class="form-group">
                                    <label>Username</label>
                                    <input type="text" class=" form-control " placeholder="Username" />
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class=" form-control" placeholder="Password" />
                                </div>
                                <p class="small">
                                    <a href="#">Forgot Password?</a>
                                </p>
                                <a href="#" class="btn_full">Sign in</a>
                                <a href="register.html " class="btn_full_outline">Register</a>
                            </form>
                        </div>
                </div>
            </div>
        </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Login
