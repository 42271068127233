import React, { Component } from 'react'
import './Home.css'
import './slider.css'
import './nice-select.css'
import {Link} from 'react-router-dom'
export class Home extends Component {
  render() {
    return (
      <div>
        {/* <section className="banner-area relative" id="home">	
				<div className="overlay overlay-bg"></div>
				<div className="container">
					<div className="row fullscreen d-flex align-items-center justify-content-center" style={{height : '625px'}}>
						<div className="banner-content col-lg-12">
							<h1 className="text-white">
								<span>1500+</span> Jobs posted last week				
							</h1>	
							<form action="search.html" className="serach-form-area">
								<div className="row justify-content-center form-wrap">
									<div className="col-lg-4 form-cols">
										<input type="text" className="form-control" name="search" placeholder="what are you looging for?" />
									</div>
									<div className="col-lg-3 form-cols">
										<div className="default-select" id="default-selects">
											<select className="form-control">
												<option value="1">Select area</option>
												<option value="2">Dhaka</option>
												<option value="3">Rajshahi</option>
												<option value="4">Barishal</option>
												<option value="5">Noakhali</option>
											</select>
										</div>
									</div>
									<div className="col-lg-3 form-cols">
										<div className="default-select" id="default-selects2">
											<select className="form-control">
												<option value="1">All Category</option>
												<option value="2">Medical</option>
												<option value="3">Technology</option>
												<option value="4">Goverment</option>
												<option value="5">Development</option>
											</select>
										</div>										
									</div>
									<div className="col-lg-2 form-cols">
									    <button type="button" className="btn btn-info">
									      <span className="lnr lnr-magnifier"></span> Search
									    </button>
									</div>								
								</div>
							</form>	
							<p className="text-white"> <span>Search by tags:</span> Tecnology, Business, Consulting, IT Company, Design, Development</p>
						</div>											
					</div>
				</div>
			</section> */}
            {/*feature section */}
            {/* <section className="features-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div className="single-feature">
								<h4>Searching</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-feature">
								<h4>Applying</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-feature">
								<h4>Security</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing.
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="single-feature">
								<h4>Notifications</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing.
								</p>
							</div>
						</div>																		
					</div>
				</div>	
			</section> */}
            {/*end feature section */}
			{/*job clider here */}
				{/* <section className="custom-job-slider popular-post-area pt-100">
				<div class="container">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div id="news-slider" class="owl-carousel">
				<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p1.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>	
							<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p2.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>
							<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p1.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>	
							<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p2.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>	
							<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p1.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>	
							<div class="single-popular-post d-flex flex-row">
								<div class="thumb">
									<img src={require("../../img/p2.png")} alt="" />
									<a class="btns text-uppercase" href="#">view job post</a>
								</div>
								<div class="details">
									<a href="#"><h4>Creative Designer</h4></a>
									<h6>Los Angeles</h6>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis.
									</p>
								</div>
							</div>
                </div>
            </div>
        </div>
    </div>
				</section> */}
			{/*end job slider here */}
            {/*featured jib section */}
            {/* <section className="feature-cat-area pt-100" id="category">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-10">
							<div className="title text-center">
								<h1 className="mb-10">Featured Job Categories</h1>
								<p>Who are in extremely love with eco friendly system.</p>
							</div>
						</div>
					</div>						
					<div className="row">
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o1.png")} alt="" />
								</a>
								<p>Accounting</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o2.png")} alt="" />
								</a>
								<p>Development</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o3.png")} alt="" />
								</a>
								<p>Technology</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o4.png")} alt="" />
								</a>
								<p>Media & News</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o5.png")} alt="" />
								</a>
								<p>Medical</p>
							</div>
						</div>
						<div className="col-lg-2 col-md-4 col-sm-6">
							<div className="single-fcat">
								<a href="category.html">
									<img src={require("../../img/o6.png")} alt="" />
								</a>
								<p>Goverment</p>
							</div>			
						</div>																											
					</div>
				</div>	
			</section> */}
            {/*end featured job section */}
            {/*post section */}
            <section className="post-area section-gap">
				<div className="container">
					<div className="row justify-content-center d-flex">
						<div className="col-lg-8 post-list">
							<ul className="cat-list">
								<li><a href="#">Recent</a></li>
								<li><a href="#">Full Time</a></li>
								<li><a href="#">Intern</a></li>
								<li><a href="#">part Time</a></li>
							</ul>
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
							<Link to ="/Single">	<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>आयडीबीआय बँक यांच्या आस्थापनेवरील सहाय्यक व्यवस्थापक पदाच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत </h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									भारतीय स्टेट बँक यांच्या आस्थापनेवरील प्रोबेशनरी ऑफिसर पदाच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत. 
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
								</Link>
							</div>
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<Link to ="/Single"><div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>आयडीबीआय बँकेत सहाय्यक व्यवस्थापक पदाच्या एकूण ५०० जागा</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									आयडीबीआय बँक यांच्या आस्थापनेवरील सहाय्यक व्यवस्थापक पदाच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत.
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
								</Link>
							</div>
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4> कंत्राटी ग्रामसवेक </h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									(i) 60% गणुासंह 12 वी उत्तीणण ककंवा अनियानंत्रकी निप्लोमा ककंवा BWS ककंवा कृषी निप्लोमा (ii) MS-CIT/CCC
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>		
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>पुणे येथे सरळसेवा/ तलाठी मेगाभरती परीक्षा स्पेशल बॅच उपलब्ध</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									एकलव्य अकॅडमी, पुणे येथे आगामी सरळसेवा/ तलाठी सरळसेवा मेगाभरती परीक्षेची तयारी करण्यासाठी सुरु होत असलेल्या.... 
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>जिल्हा परिषदांच्या आस्थापनेवर विविध पदांच्या एकूण १३५७० जागा</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									राज्यातील विविध जिल्हा परिषदांच्या आस्थापनेवरील विविध पदांच्या जागा भरण्यासाठी पदांनुसार पात्रताधारक उमेदवारांकडून केवळ ऑनलाईन पद्धतीने अर्ज मागविण्यात येत आहेत....
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>आरोग्य सवेक</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									(i) 10वी उत्तीणण  (ii) MS-CIT/CCC 
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>															
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt="" />
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="single.html"><h4>आरोग्य सनेवका</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
									(i) सहाय्यकारी प्रसानवका आनण महाराष्ट्र पररचयाण पररषदमेध्ये नोंद  (ii) MS-CIT/CCC
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>	
							
							<a className="text-uppercase loadmore-btn mx-auto d-block" href="category.html">Load More job Posts</a>

						</div>
						<div className="col-lg-4 sidebar">
							<div className="single-slidebar">
								<h4>Jobs by Location</h4>
								<ul className="cat-list">
									<li><a className="justify-content-between d-flex" href="category.html"><p>New York</p><span>37</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Park Montana</p><span>57</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Atlanta</p><span>33</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Arizona</p><span>36</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Florida</p><span>47</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Rocky Beach</p><span>27</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Chicago</p><span>17</span></a></li>
								</ul>
							</div>

							<div className="single-slidebar">
								<h4>Top rated job posts</h4>
								<div className="active-relatedjob-carusel">
									<div className="single-rated">
										<img className="img-fluid" src={require("../../img/r1.jpg")} alt="" />
										<a href="single.html"><h4>Creative Art Designer</h4></a>
										<h6>Premium Labels Limited</h6>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut dolore magna aliqua.
										</p>
										<h5>Job Nature: Full time</h5>
										<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
										<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
										<a href="#" className="btns text-uppercase">Apply job</a>
									</div>
									{/* <div className="single-rated">
										<img className="img-fluid" src={require("../../img/r1.jpg")} alt="" />
										<a href="single.html"><h4>Creative Art Designer</h4></a>
										<h6>Premium Labels Limited</h6>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut dolore magna aliqua.
										</p>
										<h5>Job Nature: Full time</h5>
										<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
										<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
										<a href="#" className="btns text-uppercase">Apply job</a>
									</div>
									<div className="single-rated">
										<img className="img-fluid" src={require("../../img/r1.jpg")} alt="" />
										<a href="single.html"><h4>Creative Art Designer</h4></a>
										<h6>Premium Labels Limited</h6>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut dolore magna aliqua.
										</p>
										<h5>Job Nature: Full time</h5>
										<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
										<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
										<a href="#" className="btns text-uppercase">Apply job</a>
									</div>																		 */}
								</div>
							</div>							

							<div className="single-slidebar">
								<h4>Jobs by Category</h4>
								<ul className="cat-list">
									<li><a className="justify-content-between d-flex" href="category.html"><p>Technology</p><span>37</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Media & News</p><span>57</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Goverment</p><span>33</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Medical</p><span>36</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Restaurants</p><span>47</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Developer</p><span>27</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Accounting</p><span>17</span></a></li>
								</ul>
							</div>

							<div className="single-slidebar">
								<h4>Carrer Advice Blog</h4>
								<div className="blog-list">
									<div className="single-blog bg-blog1">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>
									<div className="single-blog bg-blog2">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>
									<div className="single-blog bg-blog1">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>																		
								</div>
							</div>							

						</div>
					</div>
				</div>	
			</section>
            {/*end post section */}
            {/*joi section */}
            <section className="callto-action-area section-gap" id="join">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content col-lg-9">
							<div className="title text-center">
								<h1 className="mb-10 text-white">Join us today without any hesitation</h1>
								<p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
								<a className="primary-btn" href="#">I am a Candidate</a>
								<a className="primary-btn" href="#">Request Free Demo</a>
							</div>
						</div>
					</div>	
				</div>	
			</section>
            {/*end join section */}
            {/*download */}
            <section className="download-area section-gap" id="app">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 download-left">
							<img className="img-fluid" src={require("../../img/d1.png")} alt="" />
						</div>
						<div className="col-lg-6 download-right">
							<h1>Download the <br />
							Job Listing App Today!</h1>
							<p className="subs">
								It won’t be a bigger problem to find one video game lover in your neighbor. Since the introduction of Virtual Game, it has been achieving great heights so far as its popularity and technological advancement are concerned.
							</p>
							<div className="d-flex flex-row">
								<div className="buttons">
									<i className="fa fa-apple" aria-hidden="true"></i>
									<div className="desc">
										<a href="#">
											<p>
												<span>Available</span> <br />
												on App Store
											</p>
										</a>
									</div>
								</div>
								<div className="buttons">
									<i className="fa fa-android" aria-hidden="true"></i>
									<div className="desc">
										<a href="#">
											<p>
												<span>Available</span> <br />
												on Play Store
											</p>
										</a>
									</div>
								</div>									
							</div>						
						</div>
					</div>
				</div>	
			</section>               
            {/*end download */}
      </div>
    )
  }
}

export default Home
