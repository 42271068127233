import React, { Component } from 'react'
import "./Upper.css";
import { NavLink } from "react-router-dom";
export class UpperNavbar extends Component {
  render() {
    return (
        <React.Fragment>
        <div>
         <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 custom-header">
                    <div className="bg-warning" style={{padding : '50px'}}>
                    <h1 style={{textAlign : 'center'}}>SOMETHING</h1>
                    </div>
                </div>
            </div>
         </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UpperNavbar
