import React, { Component } from 'react'
import './Single.css'
import {Link, NavLink} from 'react-router-dom'
export class Single extends Component {
  render() {
    return (
        <React.Fragment>
      <div>
        {/*banner here */}
        {/* <section className="banner-area relative" id="home">	
					<div className="overlay overlay-bg"></div>
					<div className="container">
						<div className="row d-flex align-items-center justify-content-center">
							<div className="about-content col-lg-12">
								<h1 className="text-white">
									Job Details				
								</h1>	
								<p className="text-white link-nav"><a href="index.html">Home </a>  <span className="lnr lnr-arrow-right"></span>  <a href="single.html"> Job Details</a></p>
							</div>											
						</div>
					</div>
				</section> */}
        {/*end banner */}
        {/*content section */}
        <section className="post-area section-gap">
				<div className="container">
					<div className="row justify-content-center d-flex">
						<div className="col-lg-8 post-list">
							<div className="single-post d-flex flex-row">
								<div className="thumb">
									<img src={require("../../img/post.png")} alt=""/>
									<ul className="tags">
										<li>
											<a href="#">Art</a>
										</li>
										<li>
											<a href="#">Media</a>
										</li>
										<li>
											<a href="#">Design</a>					
										</li>
									</ul>
								</div>
								<div className="details">
									<div className="title d-flex flex-row justify-content-between">
										<div className="titles">
											<a href="#"><h4>Creative Art Designer</h4></a>
											<h6>Premium Labels Limited</h6>					
										</div>
										<ul className="btns">
											<li><a href="#"><span className="lnr lnr-heart"></span></a></li>
											<li><a href="#">Apply</a></li>
										</ul>
									</div>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut dolore magna aliqua.
									</p>
									<h5>Job Nature: Full time</h5>
									<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
									<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
								</div>
							</div>	
							<div className="single-post job-details">
								<h4 className="single-title">Whom we are looking for</h4>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.
								</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
								</p>
							</div>
							<div className="single-post job-experience">
								<h4 className="single-title">Experience Requirements</h4>
								<ul>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>	
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>																											
								</ul>
							</div>
							<div className="single-post job-experience">
								<h4 className="single-title">Job Features & Overviews</h4>
								<ul>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>	
									<li>
										<img src={require("../../img/pages/list.jpg")} alt="" />
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>													
								</ul>
							</div>	
							<div className="single-post job-experience">
								<h4 className="single-title">Education Requirements</h4>
								<ul>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>
									<li>
										<img src={require("../../img/pages/list.jpg")} alt=""/>
										<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquaut enim ad minim veniam.</span>
									</li>																										
								</ul>
							</div>														
						</div>
						<div className="col-lg-4 sidebar">
							<div className="single-slidebar">
								<h4>Jobs by Location</h4>
								<ul className="cat-list">
									<li><a className="justify-content-between d-flex" href="category.html"><p>New York</p><span>37</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Park Montana</p><span>57</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Atlanta</p><span>33</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Arizona</p><span>36</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Florida</p><span>47</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Rocky Beach</p><span>27</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Chicago</p><span>17</span></a></li>
								</ul>
							</div>

							<div className="single-slidebar">
								<h4>Top rated job posts</h4>
								<div className="active-relatedjob-carusel">
									<div className="single-rated">
										<img className="img-fluid" src={require("../../img/r1.jpg")} alt="" />
										<a href="single.html"><h4>Creative Art Designer</h4></a>
										<h6>Premium Labels Limited</h6>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temporinc ididunt ut dolore magna aliqua.
										</p>
										<h5>Job Nature: Full time</h5>
										<p className="address"><span className="lnr lnr-map"></span> 56/8, Panthapath Dhanmondi Dhaka</p>
										<p className="address"><span className="lnr lnr-database"></span> 15k - 25k</p>
										<a href="#" className="btns text-uppercase">Apply job</a>
									</div>
								
																										
								</div>
							</div>							

							<div className="single-slidebar">
								<h4>Jobs by Category</h4>
								<ul className="cat-list">
									<li><a className="justify-content-between d-flex" href="category.html"><p>Technology</p><span>37</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Media & News</p><span>57</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Goverment</p><span>33</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Medical</p><span>36</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Restaurants</p><span>47</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Developer</p><span>27</span></a></li>
									<li><a className="justify-content-between d-flex" href="category.html"><p>Accounting</p><span>17</span></a></li>
								</ul>
							</div>

							<div className="single-slidebar">
								<h4>Carrer Advice Blog</h4>
								<div className="blog-list">
									<div className="single-blog bg-blog1">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>
									<div className="single-blog bg-blog2">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>
									<div className="single-blog bg-blog1">
										<a href="single.html"><h4>Home Audio Recording <br />
										For Everyone</h4></a>
										<div className="meta justify-content-between d-flex">
											<p>
												02 Hours ago
											</p>
											<p>
												<span className="lnr lnr-heart"></span>
												06
												 <span className="lnr lnr-bubble"></span>
												02
											</p>
										</div>
									</div>																		
								</div>
							</div>							

						</div>
					</div>
				</div>	
			</section>
        {/*end contten section */}
        {/*footer */}
        <section className="callto-action-area section-gap">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content col-lg-9">
							<div className="title text-center">
								<h1 className="mb-10 text-white">Join us today without any hesitation</h1>
								<p className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
								<a className="primary-btn" href="#">I am a Candidate</a>
								<a className="primary-btn" href="#">We are an Employer</a>
							</div>
						</div>
					</div>	
				</div>	
			</section>
        {/*end footer */}
      </div>    
      </React.Fragment>
    )
  }
}

export default Single
