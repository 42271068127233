import React, { Component } from "react";
import "./App.css";
import { Route} from 'react-router-dom';
// import './font-awesome.min.css'
// import './bootstrap.css'
import "./nice-select.css";
import "./linearicons.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import  Single  from "./components/Single/Single";
import  Category  from "./components/Category/Category";
import  Login  from "./components/Login/Login";
import { Result } from "./components/Result/Result";
import { Syllabus } from "./components/Syllabus/Syllabus";
import { Advertisement } from "./components/Advertisement/Advertisement";
import { mega } from "./components/Mega/mega";
import { lakshvedh } from "./components/Lakshvedh/lakshvedh";
import { magova } from "./components/Magova/magova";
import  UpperNavbar  from "./components/UpperNavbar/UpperNavbar";
import { Online } from "./components/Online/Online";
import { Hallticket } from "./components/Ticket/Hallticket";

class App extends Component {
  render() {
    return (
      <div>
        {/* <UpperNavbar /> */}
        <Navbar />
        
        <Route path='/' exact component={Home} />
        <Route path='/Single' exact component={Single} />
        <Route path='/Category' exact component={Category} />
        <Route path='/Login' exact component={Login} />
        <Route path='/Result' exact component={Result} />
        <Route path='/Syllabus' exact component={Syllabus} />
        <Route path='/Advertisement' exact component={Advertisement} />
        <Route path='/Mega' exact component={mega} />
        <Route path='/lakshvedh' exact component={lakshvedh} />
        <Route path='/magova' exact component={magova} />
        <Route path='/Online' exact component={Online} />
        <Route path='/HallTicket' exact component={Hallticket} />
        <Footer />
       
      </div>
    );
  }
}

export default App;
